body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  color: #495867;
  line-height: 1.9;
  padding: 0.6rem;
}

p {
  font-size: 0.9rem;
  line-height: 0.1;
  color: #495867;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
